<!--  -->
<template>
  <div class="contact-us">
    <img src="@/assets/h5_image/coverage_.png" alt="" class="map-title" />

    <div class="map">
      <div ref="echartsmap" class="charts charts_map"></div>
    </div>
    <div class="address">
      <img
        v-if="mode == 'pc'"
        src="@/assets/image/contact.png"
        alt=""
        class="title-img"
      />
      <img
        v-if="mode == 'h5'"
        src="@/assets/h5_image/contact-us@2x.png"
        alt=""
        class="title-img"
      />
      <div class="address-item">
        <img
          src="@/assets/h5_image/beijing@2x.png"
          alt=""
          class="address-img"
        />
        <p class="name">北京总部</p>
        <p>地址：东城区崇文门外大街3号新世界中心B座8楼818室</p>
        <p>电话：010-62663638、62663639</p>
      </div>
      <div class="address-item">
        <img src="@/assets/h5_image/hefei@2x.png" alt="" class="address-img" />
        <p class="name">合肥分部</p>
        <p>地址：合肥市蜀山区兴科大厦807室</p>
        <p>电话：0551-63662863、65566128</p>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import AMapLoader from "@amap/amap-jsapi-loader";
import { fetchMapData } from "@/api/api.js";

export default {
  props: {
    mode: {
      type: String,
      default: "pc",
    },
  },
  name: "",
  components: {},
  data() {
    return {
      mapData: {},
      mapTips: [],
      geoJson: {},
      myChart: null,
      geoJsonData: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    renderEchartMap(data) {
      let mapTips = [];
      this.getGeoJson(100000).then((res) => {
        console.log("ccc", res);
        this.geoJsonData = res;
        res.features.forEach((item) => {
          //
          item.name = item.properties.name;
          // item.value = this.tabData.area_list[item.properties.adcode];
          item.value = data[item.properties.adcode];

          item.adcode = item.properties.adcode;
          // item.data = item.properties;
          item.data = data[item.properties.adcode];
          if (data[item.properties.adcode]) {
            //
            item.value = 1;
          }
          let tips_value = {
            name: item.name,
            value: [...item.properties.center, 99],
          };
          mapTips.push(tips_value);
          // item.customData = data[item.properties.adcode];
          // item.data = data[item.adcode];
          // delete item.properties;
          // console.log("----", data[item.properties.adcode]);
          // console.log("item", item);
        });
        this.mapData = res.features;
        this.mapTips = mapTips;
        console.log("maptips", mapTips);
        // 往历史记录里添加一条记录
        // this.historyList.push({
        //   data: this.mapData,
        //   geoJsonData: this.geoJsonData,
        // });
        // this.myChart.registerMap("china", this.geoJson);
        this.$nextTick(() => {
          console.log("-----------------", this.$refs.echartsmap);
          this.initEchartsMap();
        });
      });
    },
    getGeoJson(adcode) {
      //
      console.log("adcode", adcode);
      return new Promise((resolve, reject) => {
        //
        // eslint-disable-next-line no-undef
        AMapUI.loadUI(["geo/DistrictExplorer"], (DistrictExplorer) => {
          const districtExplorer = new DistrictExplorer();
          districtExplorer.loadAreaNode(adcode, (error, areaNode) => {
            if (error) {
              console.error(error);
              reject(error);
              return;
            }
            let mapJson = {
              features: {},
            };
            const Json = areaNode.getSubFeatures();
            mapJson.features = Json;
            resolve(mapJson);
            console.log("数据", Json);
          });
        });
      });
    },
    initMap(AMap) {
      console.log("amap");
      this.map = new AMap.Map("map", {
        resizeEnable: true,
        center: [116.30946, 39.937629],
        zoom: 11,
      });
      // this.getData().then((res) => {
      //   this.DataConfig = res.data.config;
      //   console.log("aaaaaaaaaaaaaaaaa", res);
      //   this.setLibraryTypeShow();
      //   this.initEchartsTop();
      //   this.initEchartsArea();
      //   // this.init();
      //   this.setTabbar();
      //   this.initEchartsEntry();
      this.getMapData().then((res) => {
        console.log("map-data", res);
        this.renderEchartMap(res);
      });
      //   // 设置活动列表
      //   this.setActivityList();
      //   this.setProvince();
      //   this.renderEchartsJoinProp();
      //   this.handleInitLeft();
      // });
    },
    initEchartsMap() {
      this.myChart = echarts.init(this.$refs.echartsmap, {
        width: 700,
        height: 700,
      });
      echarts.registerMap("myMap", this.geoJsonData);
      this.myChart.setOption(
        {
          tooltip: {
            trigger: "item",
            formatter: (p) => {
              //这里可以自定义鼠标悬浮到地图上显示的内容
              if (p.name == "安徽省") {
                // console.log("trigger", p);
              }
              let val = p.value;
              if (window.isNaN(val)) {
                val = 0;
              }

              if (p.data.data) {
                let txtCon = p.name + "" + "<br>" + "" + p.data.data.citys;
                return txtCon;
              } else {
                return "";
              }
              // return txtCon;
            },
            show: true,
            transitionDuration: 0,
          },
          title: {
            show: false,
            left: "center",
            top: "15",
            text: "分布图",
            textStyle: {
              color: "#fff",
              fontSize: 16,
            },
          },
          //这里可以添加echarts内置的，例如下载图片等
          toolbox: {
            feature: {
              dataView: {
                show: false,
              },
              magicType: {
                show: false,
              },
              restore: {
                show: false,
              },
            },
            iconStyle: {
              normal: {
                borderColor: "#41A7DE",
              },
            },
            itemSize: 15,
            top: 20,
            right: 22,
          },
          //地图颜色划分
          dataRange: {
            show: false,
            right: "2%",
            bottom: "3%",
            icon: "circle",
            align: "left",
            splitList: [
              {
                start: 0,
                end: 0,
                label: "无数据",
                color: "#eef1f8",
              },
              {
                start: 1,
                end: 99,
                label: "1-99",
                color: "#d3e0fb",
              },
            ],
            textStyle: {
              color: "#0fccff",
              fontSize: 16,
            },
          },
          // geo: {
          //   map: "myMap",
          //   tooltip: {
          //     trigger: "item",
          //     formatter: this.tooltipFormatter,
          //   },
          //   label: {
          //     normal: {
          //       show: false, //显示省份标签
          //       textStyle: {
          //         color: "rgb(0, 0, 0)", //省份标签字体颜色
          //         fontSize: 12,
          //       },
          //     },
          //     emphasis: {
          //       //对应的鼠标悬浮效果
          //       show: true,
          //       textStyle: {
          //         color: "#000",
          //       },
          //     },
          //   },
          //   itemStyle: {
          //     normal: {
          //       show: true,
          //       areaColor: "#eef1f9", //地图的颜色
          //       borderColor: "rgb(84, 112, 198)", //边界线颜色
          //       borderWidth: "1", //边界线宽
          //     },
          //     emphasis: {
          //       // 也是选中样式
          //       // borderWidth: 2,
          //       // borderColor: "#fff",
          //       areaColor: "#afc9ff",
          //     },
          //   },
          // },

          series: [
            {
              name: "分布",
              type: "map",
              map: "myMap",
              data: this.mapData,
              // geoIndex: 1,
              zoom: 0.5,
              itemStyle: {
                normal: {
                  show: true,
                  // areaColor: "#d3e0fb", //地图的颜色
                  borderColor: "rgb(84, 112, 198)", //边界线颜色
                  borderWidth: "1", //边界线宽
                },
                emphasis: {
                  // 也是选中样式
                  // borderWidth: 2,
                  // borderColor: "#fff",
                  areaColor: "#f40",
                },
              },
              select: {
                itemStyle: {
                  areaColor: "#326ffd",
                },
              },
              layoutCenter: ["10%", "100%"],
              // 如果宽高比大于 1 则宽度为 100，如果小于 1 则高度为 100，保证了不超过 100x100 的区域
              // layoutSize: 100,
            },
            // {
            //   name: "告警",
            //   type: "scatter",
            //   coordinateSystem: "geo", // 使用地理坐标系，前面我们注册过
          ],
        },
        true,
      );
      //这里调用方法时不要加括号，如果点击事件触发了好几次，你可以先:
      this.myChart.off("click"); //解绑
      this.myChart.on("click", this.clickMap);
    },
    tooltipFormatter(params) {
      // params传递当前点击区域的series数据，部分字段：seriesName 系列名 name 数据项 value 数据值
      const { name } = params; // 以选中杭州为例，这里name=杭州
      let str = `${name}<br/>`;
      // 遍历dataset，将各个维度的数据拼接到tooltip上
      // Object.values(this.dataset).forEach((item) => {
      //   const { name: seriesName, data } = item;
      //   // 判断杭州对应的value是否有值
      //   const filterData = data.filter((v) => v.name === name);
      //   const value = filterData.length > 0 ? filterData[0].value : null;
      //   // 如果有值，则按照下面的规则进行字符串拼接
      //   if (value) {
      //     switch (seriesName) {
      //       case "业务量":
      //         str = `${str}${seriesName}: ${value}<br/>`;
      //         break;
      //       case "成功率":
      //         str = `${str}${seriesName}: ${value}%<br/>`;
      //         break;
      //       case "平均时延":
      //         str = `${str}${seriesName}: ${value}ms<br/>`;
      //         break;
      //     }
      //   } else {
      //     // 对null特殊处理
      //     str = `${str}${seriesName}: ${value}<br/>`;
      //   }
      // });
      this.mapData.forEach((item) => {
        //
        let { data, name: seriesName } = item;
        if (seriesName == name && data?.citys) {
          str = `${str}${data.citys}<br/>`;
        }
      });

      console.log("=========", params);
      return str;
    },

    async getMapData() {
      try {
        let res = await fetchMapData();
        let result = {};
        for (let el of res.data.data) {
          //
          result[el.code] = el;
        }
        console.log("res", res);
        return result;
      } catch (err) {
        console.log("err", err);
        return Promise.reject(err);
      }
    },
  },
  created() {
    console.log("h5-create");
    this.getMapData();
  },
  mounted() {
    AMapLoader.load({
      key: "208ea92876277dfa4acc2a3b6c57bfa9", // 申请好的Web端开发者Key，首次调用 load 时必填
      version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      AMapUI: {
        // 是否加载 AMapUI，缺省不加载
        version: "1.1", // AMapUI 缺省 1.1
        plugins: ["geo/DistrictExplorer"], // 需要加载的 AMapUI ui插件
      },
      Loca: {
        // 是否加载 Loca， 缺省不加载
        version: "1.3.2", // Loca 版本，缺省 1.3.2
      },
    })
      .then((AMap) => {
        this.$nextTick(() => this.initMap(AMap));
      })
      .catch((e) => {
        console.error(e);
      });
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.contact-us {
  background-image: url("../assets/image/address_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 30px 20px 0;
  box-sizing: border-box;
  .map-title {
    width: 70px;
    margin: 0 0 0px 0;
    position: relative;
    bottom: -60px;
  }
  .address {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 100;
    top: -54px;
    .title-img {
      width: calc(165px / 2);
      margin-bottom: 40px;
    }
    .address-item {
      color: #fff;
      margin: 0 0 40px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      &:last-child {
        margin: 0 0;
      }
      p {
        // padding: 0 30px;
        margin: 0;
        line-height: 1.5;
      }
      .name {
        font-size: 18px;
        margin: 20px 0 20px;
      }
    }
    .address-img {
      width: 240px;
    }
  }
  .map {
    width: 70%;
    // background-color: #fff;
    min-height: 100px;
    .charts {
      height: 400px;
    }
  }
}
@media screen and (max-width: 800px) {
  .contact-us {
    display: flex;
    flex-direction: column;
    .address {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // .title-img {
      //   width: 30vw;
      // }
    }
    .map {
      margin: 0px 0 0;
      width: 100vw;
      height: 100vw;
      position: relative;
      .map-title {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        width: 100px;
        margin: 0 auto;
      }
      .charts {
        // width: 100vw;
        // height: 420px;
      }
    }
  }
}
</style>
