<template>
  <div class="home">
    <LoadingH5 v-if="isLoading"></LoadingH5>
    <div v-else class="main">
      <header class="header">
        <section class="logo-wrap">
          <router-link to="/h5" class="logo-box">
            <img src="@/assets/image/logo-icon.png" alt="" class="logo" />
            <p class="logo-text">北京一博千禧科技有限公司</p>
          </router-link>
          <div class="join-phone">
            <img src="@/assets/image/phone_icon.png" alt="" class="icon" />
            <span class="strong">400-969-6261</span>
          </div>
        </section>
        <div class="swiper-box">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="banner in banners" :key="banner.name">
              <!-- <a :href="banner.url" target="_blank" rel="noopener noreferrer"> -->
              <img
                class="swiper-image"
                :src="banner.image"
                alt="banner"
                @click="bannerClick(banner)"
              />
              <!-- </a> -->
            </swiper-slide>

            <!-- <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div> -->
          </swiper>
        </div>
      </header>
      <section class="data-wrap">
        <img src="@/assets/h5_image/data1.png" alt="" class="img" />
        <img src="@/assets/h5_image/data2.png" alt="" class="img" />
        <img src="@/assets/h5_image/data3.png" alt="" class="img" />
        <img src="@/assets/h5_image/data4.png" alt="" class="img" />
      </section>
      <section class="about-box">
        <img
          src="@/assets/h5_image/about-us@x1.png"
          alt="关于我们"
          class="title-img"
        />
        <div class="content">
          <p class="title">
            北京一博千禧科技有限公司是服务高校校园文化建设，各类图书馆、党政机关等机构客户的高科技企业。
          </p>
          <p class="text">
            以e博在线、启航简笔画、语林鸟为品牌依托，开发出e博在线阅读服务平台、启航简笔画服务平台、语林鸟校园文化建设服务平台等拳头产品，提供PC、移动端、小程序、大屏、线上、线下等多终端、多种形式的服务，完全契合和满足机构及读者需求。
          </p>
          <p class="text">
            2023年，公司迁址到首都核心功能区商圈，将为广大客户提供更加主动周到的服务。
          </p>
          <div class="tips">
            <img
              src="@/assets/h5_image/tips1@x2.png"
              alt="更丰富"
              class="icon"
            />
            <img
              src="@/assets/h5_image/tips2@x2.png"
              alt="更丰富"
              class="icon"
            />
            <img
              src="@/assets/h5_image/tips3@x2.png"
              alt="更丰富"
              class="icon"
            />
            <img
              src="@/assets/h5_image/tips4@x2.png"
              alt="更丰富"
              class="icon"
            />
            <img
              src="@/assets/h5_image/tips5@x2.png"
              alt="更丰富"
              class="icon"
            />
            <img
              src="@/assets/h5_image/tips6@x2.png"
              alt="更丰富"
              class="icon"
            />
          </div>
        </div>
      </section>

      <section class="partner-organ-box">
        <img src="@/assets/h5_image/organ-title.png" alt="" class="title-img" />
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide v-for="item in organList" :key="item.name">
            <img class="swiper-image" :src="item.image" alt="banner" />
          </swiper-slide>
          <!-- <swiper-slide >
          <img
            class="swiper-image"
            src="@/assets/image/test.png"
            alt="banner"
          />
        </swiper-slide> -->

          <!-- <div class="swiper-button-prev" slot="button-prev"></div> -->
          <!-- <div class="swiper-button-next" slot="button-next"></div> -->
        </swiper>
        <img src="@/assets/h5_image/cooperative@1x.png" alt="" class="bg" />
      </section>

      <section class="product-description">
        <img src="@/assets/image/product.png" alt="" class="title-img" />
        <nav class="nav-list">
          <div
            class="item"
            :class="{ active: item.active }"
            v-for="item in productList"
            :key="item.name"
            @click="productClick(item)"
          >
            {{ item.name }}
          </div>
        </nav>
        <div class="swiper-box">
          <swiper
            class="swiper"
            :options="ProductswiperOption"
            ref="productSwiperRef"
            @slideChangeTransitionStart="handleslideChangeTransitionStart"
          >
            <swiper-slide>
              <img
                src="@/assets/h5_image/ebo_intro.png"
                alt=""
                class="slide__img"
              />
            </swiper-slide>

            <swiper-slide>
              <div class="slide-box">
                <img
                  src="@/assets/h5_image/yll_intro.png"
                  alt=""
                  class="slide__img"
                />
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="slide-box">
                <img
                  src="@/assets/h5_image/qhjbh_intro.png"
                  alt=""
                  class="slide__img"
                />
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </section>

      <section class="brand-activity">
        <img src="@/assets/h5_image/activity@2x.png" alt="" class="title" />
        <div class="activity-body">
          <router-link
            class="list-item"
            v-for="item in activityList"
            :key="item.id"
            :to="{
              path: '/layout-h5/activity-detail-h5',
              query: { id: item.id },
            }"
            target="_blank"
          >
            <div class="time">
              <span class="year">{{ item.year }}</span>
              <span>{{ item.date }}</span>
            </div>
            <div class="item-con">
              <p class="item-title">{{ item.title }}</p>
              <p class="item-des">
                {{ item.intro }}
              </p>
            </div>
          </router-link>
        </div>
        <div class="activity-footer">
          <router-link to="/layout-h5/brand-activity-h5"
            >查看更多>></router-link
          >
        </div>
      </section>

      <ContactUsH5 key="h5" mode="h5"></ContactUsH5>
      <PageFooter></PageFooter>
    </div>
    <!--  -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import HelloWorld from "@/components/HelloWorld.vue";
import ContactUsH5 from "@/components/ContactUsH5.vue";
import PageFooter from "@/components/PageFooter";
import { fetchBanner, fetchActivityList } from "@/api/api.js";
import { hasMobileDevice, tapOpen } from "@/utils/utils.js";
import LoadingH5 from "../components/LoadingH5.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
    Swiper,
    SwiperSlide,
    ContactUsH5,
    PageFooter,
    LoadingH5,
  },
  data() {
    return {
      isLoading: true,
      swiperOption: {
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      productList: [
        {
          name: "e博在线",
          id: 0,
          active: true,
        },
        {
          name: "语林学习拓展数据库",
          id: 1,
          active: false,
        },
        {
          name: "启航简笔画",
          id: 2,
          active: false,
        },
      ],
      ProductswiperOption: {
        navigation: {
          // nextEl: ".swiper-button-next",
          // prevEl: ".swiper-button-prev",
        },
      },
      banners: [],
      activityList: [],
      organList: [],
      firstActivity: {},
    };
  },
  computed: {
    onlyClass() {
      if (this.activityList.length > 0) {
        return "";
      } else {
        return "only-class";
      }
    },
  },
  methods: {
    bannerClick(data) {
      //
      if (data.url) {
        tapOpen(data.url, "_blank");
      }
    },
    navToDetail(data) {
      let routeData = this.$router.resolve({
        path: "/layout-h5/activity-detail-h5",
        query: {
          id: data.id,
        },
      });
      tapOpen(routeData.href, "_blank");
      this.$router.push({
        path: "/layout-h5/activity-detail-h5",
        query: {
          id: data.id,
        },
      });
    },
    handleslideChangeTransitionStart(e) {
      console.log("handleslideChangeTransitionStart", e);
      let activeIndex = this.$refs.productSwiperRef.$swiper.activeIndex;
      this.productList.forEach((item) => {
        item.active = +item.id == +activeIndex ? true : false;
      });
    },
    productClick(data) {
      console.log("data", data);
      this.$refs.productSwiperRef.$swiper.slideTo(data.id);
      this.productList.forEach((item) => {
        item.active = item.id == data.id ? true : false;
      });
    },
    async getBanner() {
      try {
        let res = await fetchBanner({
          data: {
            position: 3,
          },
        });
        this.banners = res.data.data;
        console.log("res", res);
        return res;
      } catch (err) {
        console.log("err", err);
        return Promise.reject(err);
      }
    },

    async getActivityList() {
      try {
        let res = await fetchActivityList({
          data: {
            page: 1,
          },
        });
        this.activityList = res.data.data.activity_list.slice(0, 4);
        console.log("res", this.firstActivity);
        return res;
      } catch (err) {
        console.log("err", err);
        return Promise.reject(err);
      }
    },
    async getOrganList() {
      try {
        let res = await fetchBanner({
          data: {
            position: 2,
          },
        });
        console.log("res", res);
        this.organList = res.data.data;
        return res;
      } catch (err) {
        console.log("err", err);
        return Promise.reject(err);
      }
    },
  },
  created() {
    if (!hasMobileDevice()) {
      location.href = `https://www.yibo365.com.cn`;
    }
    this.getBanner();
    this.getActivityList();
    this.getOrganList();
    let promiseAll = [
      this.getBanner(),
      this.getActivityList(),
      this.getOrganList(),
    ];
    Promise.all(promiseAll).then((res) => {
      //
      console.log("==============", res);
      this.isLoading = false;
    });
  },
};
</script>

<style scoped lang="scss">
//
.home {
  max-width: 1920px;
  margin: 0 auto;
  background-color: #f7f9fc;
  .logo-wrap {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    .logo-box {
      text-decoration: none;
      display: flex;
      align-items: center;
      .logo {
        width: 25px;
        height: 25px;
      }
      .logo-text {
        margin: 0 0 0 4px;
        color: #fff;
        font-weight: bold;
      }
    }
    .join-phone {
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #fff;
      .icon {
        width: 22px;
        height: 22px;
        margin: 0 0px 0 0;
      }
      .strong {
        margin: 0 0 0 5px;
        font-size: 12px;
        // font-weight: bold;
      }
    }
  }
  .header {
    .swiper-box {
      .swiper-image {
        width: 100%;
        // object-fit: 100% 100%;
        // height: calc(750px / 2);
      }
    }
  }
  .data-wrap {
    position: relative;
    z-index: 100;
    margin: -40px 0 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    .img {
      width: calc((100% - 40px) / 2);
      // object-fit: contain;
      margin: 10px 0;
    }
  }

  .about-box {
    margin: 50px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url("../assets/image/about_bg.png");
    background-size: 100% 100%;
    .title-img {
      width: calc(214px / 2);
    }
    .content {
      min-height: 300px;
      background-size: 100% 100%;
      white-space: pre-wrap;
      box-sizing: border-box;
      .title {
        margin: 20px 20px;
        font-weight: bold;
        font-size: 16px;
        background-image: url("../assets/h5_image/about-us-bg@x1.png");
        background-size: contain;
        background-repeat: no-repeat;
        height: 60px;
        box-sizing: border-box;
        padding: 0 0px;
        text-align: center;
        display: flex;
        align-items: center;
      }
      .text {
        margin: 0px 20px 0px;
        text-align: justify;
        line-height: 1.6;
        font-size: 14px;
        text-indent: 2em;
      }
      .tips {
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .icon {
          margin: 10px 10px;
          width: 25%;
        }
      }
    }
  }

  .partner-organ-box {
    box-sizing: border-box;
    padding: 40px 20px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 570px;
      object-fit: contain;
      width: 100%;
      // z-index: -1;
    }
    .title-img {
      width: calc(291px / 2);
    }
    .swiper {
      margin: 40px 0 0;
      width: 90vw;
      .swiper-image {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .product-description {
    display: flex;
    flex-direction: column;
    .title-img {
      position: relative;
      z-index: 1;
      width: calc(255px / 2);

      margin: 0 auto 40px;
    }
    .nav-list {
      position: relative;
      z-index: 1;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      .item {
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        padding: 0 10px;
        box-sizing: border-box;
        // margin: 0 20px;
        color: #666666;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid #ffffff;
        box-shadow: 1px 2px 7px 0px rgba(52, 112, 255, 0.33);
        border-radius: 10px;
        text-align: center;
      }
      .active {
        color: #fff;
        border: 1px solid #ffffff;
        background: linear-gradient(0deg, #3470ff 0%, #a3beff 100%);
        box-shadow: 1px 2px 7px 0px rgba(52, 112, 255, 0.33);
        border-radius: 10px;
      }
    }
    .swiper-box {
      padding: 50px 0px 0;
      width: 100vw;
      box-sizing: border-box;
      .slide__img {
        width: 100%;
        // object-fit: contain;
      }
      .slide-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .slide-img {
          width: 40%;
        }

        .slide-right-box {
          // margin: 0 0 0 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .title {
            font-size: 28px;
          }
          .product-icon {
            width: 40%;
            margin: 20px 0 0;
          }
          .title-img {
            width: 200px;
          }
          .sub-title {
            font-size: 20px;
            font-weight: bold;
            position: relative;

            &::after {
              content: "";
              display: flex;
              width: 100%;
              height: 10px;
              position: absolute;
              left: 0;
              bottom: -15px;
              background: linear-gradient(90deg, #fff, #3470ff);
              border-radius: 5px;
            }
          }
          ul {
            margin: 20px 0 0;
            padding: 0;
            list-style: none;
            li {
              font-size: 14px;
              margin: 0.5em;
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .brand-activity {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 160px;
    background-image: url("../assets/image/activity-bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    .title {
      object-fit: cover;
      margin: 40px 0 40px 0;
      width: calc(182px / 2);
    }
    .activity-body {
      display: flex;
      flex-direction: column;
      .list-item {
        text-decoration: none;
        color: #333;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        width: calc((100vw - 40px));
        margin: 0 20px 20px;
        .time {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100px;
          height: 120px;
          background-color: #95b4fe;
          // border-radius: 10px;
          color: #fff;

          margin: 0 0px 0 0;
          .year {
            font-weight: bold;
            font-size: 28px;
          }
        }
        .item-con {
          background-color: #fff;
          flex: 1;
          height: 120px;
          box-sizing: border-box;
          white-space: pre-wrap;
          display: flex;
          flex-direction: column;
          padding: 20px 20px;
          justify-content: space-between;
          .item-title {
            line-height: 1.5;
            font-weight: bold;
            margin: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
          .item-des {
            line-height: 1.5;
            font-size: 14px;
            margin: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
    .activity-footer {
      padding: 20px 0 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 30%;
      color: #3470ff;
      white-space: nowrap;
      position: relative;
      a {
        color: #999;
        text-decoration: none;
      }
      &::after {
        content: "";
        display: flex;
        width: 100px;
        height: 2px;
        position: absolute;
        left: 120%;
        background: linear-gradient(90deg, #999, #fff);
      }
      &::before {
        content: "";
        display: flex;
        width: 100px;
        height: 2px;
        position: absolute;
        right: 120%;
        background: linear-gradient(90deg, #fff, #999);
      }
    }
  }
}
</style>
